.t-break
	display block
	width 1px
	height 1px

	// Modifiers
	for name, breakpoint in breakpoints
		display inline
		+above(breakpoint)
			&_{name}
				display block
