.section
	display block
	font-size 0


	&__illustration
		position absolute

		+above(size-m)
			position relative
			display inline-block
			vertical-align top
			width 15%
			padding 5px 0 0


	&__logo
		width 50px
		height auto
		margin 0
		padding 0

		+above(size-s)
			width 60px

		+above(size-m)
			width 110px

		+above(size-l)
			width 148px



	&__content
		text-align left
		padding 30px 0 0

		+above(size-s)
			padding 66px 0 0

		+above(size-m)
			display inline-block
			vertical-align top
			width 85%
			padding 0 0 0 25px



	&__title
		color #000
		font-size 22px
		font-weight 600
		padding 0 0 0 60px

		+above(size-s)
			padding 0 0 0 70px

		+above(size-m)
			padding 0

		+above(size-l)
			font-size 38px
			letter-spacing -1px
			word-spacing -5px



	&__fields
		padding 24px 0 0 2px



	&__field
		color #000
		font-size 14px
		line-height 1.4

		+above(size-m)
			font-size 16px
			line-height 2.1

		+above(size-l)
			font-size 20px
			letter-spacing -1px



	&__contacts
		padding 25px 0 0

		+above(size-m)
			width 40%
			padding 25px 0 0 15px

		+above(size-l)
			padding 40px 0 0 23px
