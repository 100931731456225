.contacts
	display block



	&__title
		padding 0 10px 4px
		color #000
		font-size 24px
		font-weight 600
		border-bottom 2px solid #000



	&__list
		padding 15px 0 0

		+above(size-l)
			padding 26px 0 0 0



	&__item
		margin 0 0 20px
		padding 0 0 0 16px
		font-size 0

		+above(size-l)
			padding 0 0 0 32px
			margin 0 0 25px



	&__illustration
	&__text
		display inline-block
		vertical-align middle



	&__illustration
		width 28px
		height 28px

		+above(size-l)
			width 35px
			height 35px





	&__icon
		width 100%
		height auto
		margin 0
		padding 0



	&__text
		font-size 16px
		padding 0 0 0 14px

		+above(size-l)
			font-size 20px



	&__link
		display block
		margin 0 0 7px
		color #101947
		text-decoration none
		opacity 1
		transition opacity .3s ease

		&:hover
			text-decoration none
			opacity .8

		&:last-child
			margin-bottom 0

		// Modifiers
		&_decorated
			text-decoration underline
