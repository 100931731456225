.page
	position relative
	display block
	min-height 100%
	height 100%

	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
		display flex
		flex-direction row



	&__body
		display flex
		justify-content center
		align-items center
		min-height 100%
		font-family Arial, sans-serif
		background #fff

		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
			flex-direction column
			flex auto
			width 100%



	&__section
		width 100%
		padding 15px

		+above(size-l)
			max-width 1068px
